<template>
  <div>
      {{ label ? $t(label).concat(': ') : ''}}
    <v-layout wrap style="width:100px;" align-center>
      <v-flex xs6
        style="border-style: solid;border-width: 1px;border-color:#CCC;border-radius: 15px 0 0 4px;"
        :style="{ 'background-color' : selectedPrimaryColor}"
        @click="showBG = !showBG"
      >
        <center style="padding: 10px 0;">
          <v-icon :style="{color:oppositePrimaryColor}">{{ iconBGAndText ? 'mdi-format-color-fill' : 'mdi-numeric-1-box-outline' }}</v-icon>
        </center>
      </v-flex>
      <v-flex xs6
        style="border-style: solid;border-width: 1px 1px 1px 0;border-color:#CCC;border-radius: 0 4px 15px 0;"
        :style="{ 'background-color' : selectedSecondaryColor}"
        @click="showText = !showText"
      >
        <center style="padding: 10px 0;">
          <v-icon :style="{color: oppositeSecondaryColor}">{{ iconBGAndText ? 'mdi-format-color-text' : 'mdi-numeric-2-box-outline' }}</v-icon>
        </center>
      </v-flex>
    </v-layout>
    <v-dialog v-model="showBG" persistent max-width="330px" >
      <v-card style="padding: 10px;">
      <v-color-picker
        v-model="selectedPrimaryColor"
        class="ma-2"
        :swatches="swatches"
        show-swatches
        mode="hexa"
      />
      <center>
        <v-btn
          @click="handleClearPrimaryColor"
          style="margin-bottom:10px;"
        >
          <v-icon>mdi-water-off</v-icon> {{$t('common.clear')}}
        </v-btn>
        <v-btn
          @click="handleChangeColor"
          color="primary"
          dark
          style="margin-left:10px;margin-bottom:10px;"
        >
          <v-icon>mdi-close</v-icon> {{$t('common.close')}}
        </v-btn>
      </center>
    </v-card>
    </v-dialog>
    <v-dialog v-model="showText" persistent max-width="330px" >
      <v-card style="padding: 10px;">
      <v-color-picker
        v-model="selectedSecondaryColor"
        class="ma-2"
        :swatches="swatches"
        show-swatches
        mode="hexa"
      />
      <center>
        <v-btn
          @click="handleClearSecondaryColor"
          style="margin-bottom:10px;"
        >
          <v-icon>mdi-water-off</v-icon> {{$t('common.clear')}}
        </v-btn>
        <v-btn
          @click="handleChangeColor"
          color="primary"
          dark
          style="margin-left:10px;margin-bottom:10px;"
        >
          <v-icon>mdi-close</v-icon> {{$t('common.close')}}
        </v-btn>
    </center>
    </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import tinycolor from 'tinycolor2'
  export default {
    name: 'ColorPickerPrimarySecondary',
    props: {
      label: {
        type: String,
        required: false,
        default: null
      },
      iconBGAndText: {
        type: Boolean,
        required: false,
        default: false
      },
      width: {
        type: String,
        required: false,
        default: null
      },
      height: {
        type: String,
        required: false,
        default: '36px'
      },
      primaryColor: {
        type: String,
        required: false,
        default: null
      },
      secondaryColor: {
        type: String,
        required: false,
        default: null
      },
      clearValue: {
        type: String,
        default: null,
      },
      onChangeColor: {
        type: Function,
        required: true
      }
    },
    data: () => ({
      toggle_one: null,
      showBG: false,
      showText: false,
      swatches: [
        ['#FF0000', '#AA0000', '#550000'],
        ['#FFFF00', '#AAAA00', '#555500'],
        ['#00FF00', '#00AA00', '#005500'],
        ['#00FFFF', '#00AAAA', '#005555'],
        ['#FFFFFF', '#0000AA', '#000055'],
      ],
      selectedPrimaryColor: null,
      selectedSecondaryColor: null,
      oppositePrimaryColor: null,
      oppositeSecondaryColor: null,
    }),
    watch: {
      primaryColor (v) {
        this.selectedPrimaryColor = v
      },
      secondaryColor (v) {
        this.selectedSecondaryColor = v
      },
      /*selectedPrimaryColor (v) {
        console.log('lllllllselect', v)
        this.oppositePrimaryColor = v ? this.getOppositeColor(v && v.hex ? v.hex : v) : 'black'
        console.log('jhm',v, this.oppositePrimaryColor)
      },
      selectedSecondaryColor (v) {
        this.oppositeSecondaryColor = this.getOppositeColor(v && v.hex ? v.hex : v)
      },*/
    },
    mounted () {
      this.selectedPrimaryColor = this.primaryColor
      this.selectedSecondaryColor = this.secondaryColor
    },
    methods: {
      getOppositeColor(color) {
        if (!color) return 'black'//return '#000'
        return tinycolor(color).isLight()  ? '#000' : '#FFF'
      },
      handleChangeColor () {
        this.showBG = false
        this.showText = false
        this.onChangeColor(
          this.selectedPrimaryColor && this.selectedPrimaryColor.hex ? this.selectedPrimaryColor.hex : this.selectedPrimaryColor, 
          this.selectedSecondaryColor && this.selectedSecondaryColor.hex ? this.selectedSecondaryColor.hex : this.selectedSecondaryColor)
      },
      handleClearPrimaryColor () {
        this.selectedPrimaryColor = this.clearValue
        this.oppositePrimaryColor = 'black'
        this.handleChangeColor ()
      },
      handleClearSecondaryColor () {
        this.selectedSecondaryColor = this.clearValue
        this.oppositeSecondaryColor = 'black'
        this.handleChangeColor ()
      },
    }
  }
</script>
