<template>
  <v-card-actions class="actionButton">
    <v-layout wrap>
      <v-flex xs4 style="padding-right:5px;">
        <delete-item
          v-if="!isNew && !isClone && onDeleteItem"
          :id="id"
          :onDeleteItem="onDeleteItem"
          :onlyIcon="!drawer || icon"
          :label="deleteLabel"
          block
        />
      </v-flex>
      <v-flex xs4 style="padding: 0 5px;">
        <v-btn
          v-if="drawer && !icon"
          @click="onClose"
          block
        >
          {{ $t(cancelLabel ? cancelLabel : 'common.cancel' ,locale) }}
        </v-btn>
        <v-btn
          v-else
          @click="onClose"
          block
        >
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>
      </v-flex>
      <v-flex xs4 style="padding-left:5px;">
        <v-btn
          v-if="drawer && !icon"
          :color="color ? color : 'primary'"
          @click="onSaveItem"
          block
          dark
        >
        {{ $t(isClone ? 'common.clone' : 'common.save' ,locale) }}
        </v-btn>
        <v-btn
          v-else
          :color="color ? color : 'primary'"
          @click="onSaveItem"
          block
          dark
        >
        <v-icon>{{ isClone ? 'mdi-content-copy' : 'mdi-content-save' }}</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
</v-card-actions>
</template>
<!--template>
  <v-card-actions>
    <v-spacer />
    <delete-item
      v-if="id"
      :id="id"
      :onDeleteItem="onDeleteItem"
      :onlyIcon="!drawer"
    />
    <v-btn
      v-if="drawer"
      @click="onClose"
    >
      {{ $t('common.cancel' ,locale) }}
    </v-btn>
    <v-btn
      v-else
      @click="onClose"
    >
      <v-icon>mdi-close-thick</v-icon>
    </v-btn>
    <v-btn
      v-if="drawer"
      color="primary"
      @click="onSaveItem"
    >
    {{ $t('common.save' ,locale) }}
    </v-btn>
    <v-btn
      v-else
      color="primary"
      @click="onSaveItem"
    >
      <v-icon>mdi-content-save</v-icon>
    </v-btn>
</v-card-actions>
</template-->
<script>
  import { mapState } from 'vuex'
  import DeleteItem from './DeleteItem' 

  // Destructure only the keys you want to use
  export default {
    components: { DeleteItem },
    props: {
      id: {
        type: String,
        required: false,
        default: null
      },
      onDeleteItem: {
        type: Function,
        required: false,
        default: null
      },
      onSaveItem: {
        type: Function,
        required: false,
        default: null
      },
      onClose: {
        type: Function,
        required: false,
        default: null
      },
      deleteLabel: {
        type: String,
        default: null,
      },
      cancelLabel: {
        type: String,
        default: null,
      },
      locale: {
        type: String,
        required: true
      },
      isClone: {
        type: Boolean,
        default: false,
      },
      color: {
        type: String,
        default: null,
      },
    },
    data: () => ({
      icon: false,
    }),
    computed: {
      ...mapState('app',['drawer']),
      isNew () {
        return !this.id || this.id === 'new'
      },
    },
    mounted () {
      this.checkWidth()
    },
    methods: {
      checkWidth () {
        const e = document.getElementsByClassName('actionButton')
        if (e.length === 0) return
        this.icon = e.[0].offsetWidth < 380 ? true : false
      },
    },
  }
</script>
