<template>
  <v-dialog v-model="dialog" max-width="500px">      
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="onlyIcon"
        color="red"
        dark
        v-bind="attrs"
        v-on="on"
        :block="block"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-icon
        v-else-if="icon"
        small
        v-bind="attrs"
        v-on="on"
        color="red"
        :block="block"
      >
        mdi-delete
      </v-icon>
      <v-btn
        v-else
        color="red"
        dark
        v-bind="attrs"
        v-on="on"
        :block="block"
        :xSmall="isInList"
      >
        <v-icon v-if="isInList" x-small>
          mdi-order-bool-ascending-variant
        </v-icon>
        {{ $t(label ? label : 'common.delete', locale) }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">{{ $t('common.messages.confirmDelete', locale) }}</v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="dialog = false">{{ $t('common.cancel', locale) }}</v-btn>
        <v-btn color="red" dark @click="handleDelete">{{ $t('common.delete', locale) }}</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    props: {
      id: {
        type: [String, Array],
        requied: true,
      },
      icon: {
        type: String,
        requied: false,
        default: null
      },
      onDeleteItem: {
        type: Function,
        requied: true
      },
      label: {
        type: String,
        default: null,
      },
      locale: {
        type: String,
        requied: true
      },
      onlyIcon: {
        type: Boolean,
        default: false,
      },
      block: {
        type: Boolean,
        default: false,
      },
      isInList: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        dialog: false
      }
    },
    methods: {
      handleDelete () {
        this.onDeleteItem(this.id)
        this.dialog = false
      }
    }
  }
</script>
